import {Menu, MenuItemLink, MenuItemLinkProps} from "react-admin";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import {
  FeedOutlined,
  BusinessOutlined,
  BusinessCenterOutlined,
  SecurityOutlined,
  HelpOutline,
  Report,
  HowToRegOutlined,
  AssignmentTurnedIn,
  SettingsOutlined,
} from "@mui/icons-material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import {FATHER_ROUTES, ROUTES} from "shared/constants/ROUTES";
import {useLocation} from "react-router-dom";
import SpeakerGroupIcon from "@mui/icons-material/SpeakerGroup";
import SpeakerIcon from "@mui/icons-material/Speaker";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import {MessagesUnread} from "./components/MessagesUnread";
import {Box} from "@mui/material";
import {useChatInit} from "pages/chat/hooks/useChatInit";
import React from "react";

const CustomMenuItemLink = ({to, primaryText, leftIcon, ...rest}: MenuItemLinkProps) => {
  const {pathname} = useLocation();

  const isNeedHighlight = () => {
    if (pathname.startsWith("/company/v2/admin/list-companies") && to === "company/v2") {
      return false;
    }

    if (
      (pathname.startsWith("/procedures/complaints") ||
        pathname.startsWith("/procedures/companyplaints")) &&
      to === "procedures"
    ) {
      return false;
    }

    if (
      pathname.startsWith("/procedures/companyplaints") &&
      to === "procedures/complaints"
    ) {
      return true;
    }

    return pathname.startsWith(`/${to}`);
  };

  return (
    // @ts-expect-error https://github.com/marmelab/react-admin/issues/9737
    <MenuItemLink
      {...rest}
      to={to}
      primaryText={primaryText}
      leftIcon={leftIcon}
      sx={({palette}) => ({
        gap: 2,
        alignItems: "center",
        color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        "&.RaMenuItemLink-active": {
          color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        },
        svg: {
          color: isNeedHighlight() ? palette.primary.main : palette.text.secondary,
        },
      })}
    />
  );
};

const getIconForRoute = (path) => {
  const icons = {
    [ROUTES.NEWS.path]: <FeedOutlined />,
    [ROUTES.BLOG.path]: <ArticleOutlinedIcon />,
    [ROUTES.FAQ.path]: <HelpOutline />,
    [ROUTES.CHAT.path]: (
      <Box sx={{position: "relative"}}>
        <ChatOutlinedIcon />
        <MessagesUnread />
      </Box>
    ),
    [ROUTES.COMPANY_TICKETS.path]: <BusinessOutlined />,
    [ROUTES.COMPANY_LIST.path]: <BusinessCenterOutlined />,
    [ROUTES.SALES_CABINET.path]: <BusinessCenterOutlined />,
    [ROUTES.COMPANY_TICKETS_STATUSES.path]: <HowToRegOutlined />,
    [ROUTES.USERS.path]: <GroupOutlinedIcon />,
    [ROUTES.PROCEDURE_TICKETS.path]: <AssignmentTurnedIn />,
    [ROUTES.SAFE_DEAL_TICKETS.path]: <SecurityOutlined />,
    [ROUTES.SOPROCEDURES_TICKETS.path]: <Diversity3OutlinedIcon />,
    [ROUTES.COMPLAINTS_TICKETS.path]: <Report />,
    [ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path]: <BlockOutlinedIcon />,
    [ROUTES.CONTROL.path]: <SettingsOutlined />,
    [ROUTES.GROUP_TARIFFS.path]: <SpeakerGroupIcon />,
    [ROUTES.TARIFFS.path]: <SpeakerIcon />,
    [ROUTES.PROMO.path]: <CardGiftcardOutlinedIcon />,
    [ROUTES.INVOICE.path]: <ReceiptOutlinedIcon />,
    [ROUTES.ASSETS.path]: <LoyaltyOutlinedIcon />,
  };
  return icons[path] || null;
};

const MySideBar = (props) => {
  const {handleRoutesPermission, ...rest} = props;
  useChatInit();

  const routes = React.useMemo(() => {
    return Object.keys(ROUTES).filter((routeKey) => {
      const route = ROUTES[routeKey];
      return handleRoutesPermission(route.path);
    });
  }, [handleRoutesPermission]);

  const menuItems = routes.map((routeKey) => {
    const route = ROUTES[routeKey];
    return (
      <CustomMenuItemLink
        to={route.path}
        key={route.path}
        primaryText={route.name}
        leftIcon={getIconForRoute(route.path)}
      />
    );
  });

  return (
    <Menu {...rest} sx={{mt: 6, height: "90vh"}}>
      {menuItems}
    </Menu>
  );
};

export default MySideBar;

export const FatherSideBar = (props) => {
  return (
    <Menu {...props} sx={{mt: 6, height: "90vh"}}>
      <CustomMenuItemLink
        to={FATHER_ROUTES.LIST_ADMIN.path}
        key={FATHER_ROUTES.LIST_ADMIN.path}
        primaryText={FATHER_ROUTES.LIST_ADMIN.name}
        leftIcon={<SettingsOutlined />}
      />
    </Menu>
  );
};
