import {safeDealTheme} from "./ticketsTheme";

export const ROUTES = {
  NEWS: {name: "Новости", path: "news/news"},
  BLOG: {name: "Блог", path: "blog"},
  FAQ: {name: "Вопросы-ответы", path: "news/faq"},

  CHAT: {
    name: "Чат",
    path: "chat",
  },

  COMPANY_LIST: {name: "Компании", path: "company/v2/admin/list-companies"},
  COMPANY_INFO: {name: "Информация о компании", path: "company/v2/info"},
  COMPANY_TICKETS: {name: "Регистрации компаний", path: "company/v2"},
  PROCEDURE_TICKETS: {name: "Торговые процедуры", path: "procedures"},
  SAFE_DEAL_TICKETS: {name: "Безопасные сделки", path: "safe-deal"},
  SOPROCEDURES_TICKETS: {name: "Совместные закупки", path: "soprocedures"},
  COMPLAINTS_TICKETS: {name: "Жалобы", path: "procedures/complaints"},
  COMPANY_COMPLAINTS_TICKETS: {name: "Жалобы", path: "procedures/companyplaints"},
  COMPANY_TICKETS_STATUSES: {name: "Статусы компании", path: "company-statuses-tickets"},

  USERS: {name: "Пользователи", path: "users/v2/admin/list-users"},
  USERS_AND_COMPANY_BLOCKS_LIST: {
    name: "Блокировки и нарушения",
    path: "users/v2/admin/list-warns-and-blocks-of-users-and-companies",
  },
  BLOCKING_USER_OR_COMPANY: {
    name: "Блокирование или вынесение предупреждения",
    path: "users/v2/admin/warn-or-block-user-or-company",
  },

  COMPANY_FILES: {
    name: "Файлы",
    path: "company/v2/admin/company-files-list-admin",
  },
  BLOCKS_OF_COMPANY: {
    name: "Блокировки компанией",
    path: "list-users-blocks-in-company",
  },
  SAFE_DEAL_ALL_DEALS: {
    name: "Реестр сделок",
    path: "safe-deal/list-deals/all",
  },
  SAFE_DEAL_PAYMENTS: {
    name: "Реестр платежей",
    path: "safe-deal/payments/list/all",
  },

  GROUP_TARIFFS: {
    name: "Группы тарифов",
    path: "fin/v2/admin/tariff-group/list",
  },
  TARIFFS: {
    name: "Тарифы",
    path: "fin/v2/admin/tariff/list",
  },
  PROMO: {
    name: "Промо",
    path: "fin/v2/admin/list-promos",
  },
  PROMO_CREATE: {
    name: "Создать Промо",
    path: "fin/v2/admin/create-promo",
  },
  PROMO_GET_ONE: {
    name: "Промо",
    path: "fin/v2/admin/get-promo",
  },
  INVOICE: {
    name: "Выставленные счета",
    path: "fin/v2/admin/list-invoices",
  },
  ASSETS: {
    name: "Активы",
    path: "fin/v2/admin/list-assets",
  },

  SALES_CABINET: {
    name: "Список компаний",
    path: "sales-cabinet",
  },

  CONTROL: {name: "Обслуживание", path: "control"},
} as const;

export const complaintsTicketsTheme = ["Жалоба на процедуру", "Жалоба на компанию"];

/**
 * Константа, определяющая соответствие между ключами (url) и фильтрами по типам запросов для отображения таблицы запросов.
 */
export const TICKET_ROUTES = {
  [ROUTES.COMPANY_TICKETS.path]: ["Регистрация организации"],
  [ROUTES.PROCEDURE_TICKETS.path]: ["Торговая процедура"],
  [ROUTES.SAFE_DEAL_TICKETS.path]: safeDealTheme,
  [ROUTES.SOPROCEDURES_TICKETS.path]: ["Регистрация совместной закупки"],
  [ROUTES.COMPLAINTS_TICKETS.path]: complaintsTicketsTheme,
  [ROUTES.COMPANY_TICKETS_STATUSES.path]: ["Запрос на получение статуса производителя"],
} as const;

/**
 * Константа, определяющая соответствие между ключами действий (actions) и правами доступа (permissions).
 */
export const PERMISSIONS_FOR_ACTIONS = {
  updateCompanyEmployees: "operator:company:update_employees",
  updateCompanyProfile: "operator:company:update_profile",
  // blockApplicationOffer: "operator:blockapplicationoffer",
  // blockPurchaseOrder: "blockpurchaseorder",
  blockCompany: "operator:company:block",
  blockUser: "operator:user:block",
  // approvePurchaseOrder: "operator:approvepurchaseorder",
  approveCompanyVerificationStatus: "operator:company:approve_verification_status",
  addSalesCommentToCompany: "operator:edit_company_sales_helpers",
  editTariffs: "operator:edit_tariffs",
  markInvoiceAsPaid: "operator:mark_invoices_as_paid",
  createNewPromos: "operator:create_new_promos",
} as const;

/**
 * Константа, определяющая соответствие между ключами маршрутов (routes) и правами доступа (permissions).
 */
export const PERMISSIONS_FOR_ROUTES = {
  [ROUTES.NEWS.path]: "operator:access:news",
  [ROUTES.BLOG.path]: "operator:access:blog",
  [ROUTES.FAQ.path]: "operator:access:news" /* "operator:access:faq", */,
  [ROUTES.COMPANY_LIST.path]: "operator:access:companies",
  [ROUTES.COMPANY_TICKETS.path]: "operator:access:company_subscriptions",
  [ROUTES.PROCEDURE_TICKETS.path]: "operator:access:etp_procedures",
  [ROUTES.SAFE_DEAL_TICKETS.path]: "operator:access:safe_deals",
  [ROUTES.SOPROCEDURES_TICKETS.path]: "operator:access:joint_deals",
  [ROUTES.COMPLAINTS_TICKETS.path]: "operator:access:violations",
  [ROUTES.COMPANY_TICKETS_STATUSES.path]: "operator:company:approve_registration",
  [ROUTES.CONTROL.path]: "operator:access:service",
  [ROUTES.USERS.path]: "operator:access:users",
  [ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path]: "operator:access:users",
  [ROUTES.CHAT.path]: "operator:access:chat",
  [ROUTES.SALES_CABINET.path]: "operator:access:company_readonly_list_for_sales",
  [ROUTES.GROUP_TARIFFS.path]: "operator:access:tariffs",
  [ROUTES.TARIFFS.path]: "operator:access:tariffs",
  [ROUTES.INVOICE.path]: "operator:access:invoices",
  [ROUTES.PROMO.path]: "operator:access:promos",
  [ROUTES.ASSETS.path]: "operator:access:assets",
} as const;

export const CONTENT_MANAGER = "OperatorContent" as const;
export const OPERATOR = "OperatorETP" as const;

export const HTTP_403_FORBIDDEN = 403 as const;
export const HTTP_406 = 406 as const;

export const FATHER_ROUTES_LIST = ["users/v2/r/lo"];

export const FATHER_ROUTES = {
  LIST_ADMIN: {name: "Список операторов", path: "users/v2/r/lo"},
} as const;
